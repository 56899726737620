import React, { useState } from 'react'
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import Marquee from "react-double-marquee"
import SEO from "../components/seo"
import SpotlightArticle from "../components/SpotlightArticle"
import thumbnail1 from "../media/img/development/dev-thumb1.jpg"
import { FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa'

let marqueeSettings = {
  direction: "left"
}

export default function Connect(props) {
    const [filterState, setfilterState] = useState('nav-closed')
    function filterActive(obj){
        if(filterState === `all`){
          setfilterState('all')
        }
        else if(filterState === `del`){
            setfilterState('del')
          }
        else{
          setfilterState('mum')
        }
    }
    let department = [{
        'department_label':'Leadership',
        'department_slug': 'management-team'
    },
    {
        'department_label':'Account Services Team',
        'department_slug': 'account-services'
    },
    {
        'department_label':'Creative Team',
        'department_slug': 'creative'
    },
    {
        'department_label':'Interwebs & Production Team', 
        'department_slug': 'creative-lodge'    
    },
    {
        'department_label':'Strategic Planning Team', 
        'department_slug': 'planning'
    },
    {
        'department_label':'Studio', 
        'department_slug': 'studio'
    },
    {
        'department_label':'Finance Team', 
        'department_slug': 'finance'
    },
    {
        'department_label':'Admin Team', 
        'department_slug': 'admin'
    },
    {
        'department_label':'HR Team',
        'department_slug': 'hr'
    },
    {
        'department_label':'IT Team',
        'department_slug': 'it'
    }]
    return (
        <Layout pageInfo={{ pageName: "connect" }}>
            <SEO title="Connect" />
            {props.data.allIntranetPost.edges.map(connect => (
                <SpotlightArticle tags={connect.node.title} title={connect.node.excerpt} height={30} bgColor={`#36d5d4`} />
            ))}
            <section className="directory">
                <Container>
                    <div className="row  mt-lg-5 pt-lg-5 justify-content-between">
                    <div className="col-lg-4">
                    <small className="breadcrumb">
                        <Link to="/">Home</Link> <div className="separator">/</div> <Link to={`/who-we-are`}>Who We Are</Link> <div className="separator">/</div> Connect
                    </small>
                    </div>
                    <div className="col-lg-4">
                    <small className="breadcrumb justify-content-end">
                        <span className="filter" onClick={() => filterActive(`all`)}>All</span> <div className="separator">/</div> <span className="filter" onClick={() => filterActive(`del`)}>Delhi</span> <div className="separator">/</div> <span className="filter" onClick={() => filterActive(`mum`)}>Mumbai</span>
                    </small>
                    </div>
                    </div> 
                    {department.map(department => (
                        <>
                        <Row>
                            <Col>
                                <h3>{department.department_label}</h3>
                            </Col>
                        </Row>
                        <Row>
                            {props.data.allIntranetEmployees.edges.map(directory =>{
                                if(department.department_slug === directory.node.department_slug)
                                    {
                                        return (
                                        <Col className="mb-5 del" lg={3} key={directory.id}>
                                            <div className="cell">
                                                <div className="flip-card">
                                                    <div className="flip-card-inner">
                                                        <div className="flip-card-front">
                                                            <img src={directory.node.image === ``? thumbnail1 : directory.node.image} alt="" />
                                                        </div>
                                                        <div className="flip-card-back d-flex align-items-center justify-content-center">
                                                            <div className="contact">
                                                                <p><a href={directory.node.phone === ``? `Phone no. not available` : `tel:${directory.node.phone}`}><FaPhoneAlt /> {directory.node.phone === ``? `Phone no. not available` : directory.node.phone}</a></p> 
                                                                <hr className="my-2" />
                                                                <p><a href={`mailto:${directory.node.email}`}><FaRegEnvelope /> {directory.node.email}</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h4 className="mt-4">{directory.node.name}</h4>
                                                <div className="detail">
                                                    <small>{directory.node.designation === ``? `Designation not available` : directory.node.designation}</small>
                                                    <hr className="my-0" />
                                                    <small>{directory.node.department === ``? `Department not available` : directory.node.department}</small>
                                                </div>
                                            </div>
                                        </Col>
                                        )
                                    }
                                }
                            )}
                        </Row>
                        </>
                    ))}
                    <Row>
                    </Row>
                </Container>
                <div className="home__news__Marquee">
                    <Marquee {...marqueeSettings}>
                        Walk in stupid &bull; The work comes first &bull; Fail Harder &bull; Independence is everything &bull; Walk in stupid &bull; The work comes first &bull; Fail Harder &bull; Independence is everything &bull; Walk in stupid &bull; The work comes first &bull; Fail Harder &bull; Independence is everything &bull; Walk in stupid &bull; The work comes first &bull; Fail Harder &bull; Independence is everything &bull; Walk in stupid &bull; The work comes first &bull; Fail Harder &bull; Independence is everything &bull; Walk in stupid &bull; The work comes first &bull; Fail Harder &bull; Independence is everything 
                    </Marquee>
                </div>
                {/* <div className="home__relative__links">
                <Container>
                    <Row className="d-flex">
                        <Col>
                            <BorderLayout color={`#A8233D`}>
                                <div className="links__Wrap">
                                    <RelatedArticleSingle title={`Who We Are`} slug={`/who-we-are`} />
                                </div>
                            </BorderLayout>
                        </Col>
                        <Col>
                            <BorderLayout color={`#30A2DE`}>
                                <div className="links__Wrap">
                                    <RelatedArticleSingle title={`The Cheat Sheet`} slug={`/the-cheat-sheet`} />
                                </div>
                            </BorderLayout>
                        </Col>
                        <Col>
                            <BorderLayout color={`#FFB100`}>
                                <div className="links__Wrap">
                                    <RelatedArticleSingle title={`Learn & Grow`} slug={`/learn-and-grow`} />
                                </div>
                            </BorderLayout>
                        </Col>
                        <Col>
                            <BorderLayout color={`#8163E7`}>
                                <div className="links__Wrap">
                                    <RelatedArticleSingle title={`What's Happening`} slug={`/whats-happening`} />
                                </div>
                            </BorderLayout>
                        </Col> 
                    </Row>
                </Container>
                </div> */}
            </section>
        </Layout>
    )
}
export const query = graphql`
    query Directory {
      allIntranetEmployees(sort: {fields: name}) {
        edges {
          node {
            id
            id__normalized
            name
            image
            phone
            department
            department_slug
            designation
            doj
            dob
            email
          }
        }
      }
      allIntranetPost(filter: {title: {eq: "connect"}}) {
        edges {
            node {
                id
                id__normalized
                title
                excerpt
                content
            }
        }
    }
    }
`